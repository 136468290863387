export class AnnotationRenderer {
  constructor(ctx, options) {
    this.ctx = ctx;
    this.options = options;
  }

  drawLineHeightAnnotation(x, bottomY, highestDataY) {
    const ctx = this.ctx;
    const options = this.options;
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x, bottomY);
    ctx.lineTo(x, highestDataY);
    ctx.lineWidth = options.lineWeight ? options.lineWeight : 1.5;
    ctx.strokeStyle = options.color ? options.color : '#000';
    ctx.fillStyle = options.color ? options.color : '#000';
    ctx.stroke();
    ctx.restore();
  }
}

export class LineChartUtils {
  constructor(chart) {
    this.chart = chart;
  }

  isTooltipActive() {
    return this.tooltip?._active && this.tooltip?._active?.length;
  }

  getMaximumDimensions(axis) {
    if (axis.ticksAsNumbers) {
      const tickMax = axis.ticksAsNumbers[0]; // first index is always the tallest
      const tickLow = axis.ticksAsNumbers[axis.ticksAsNumbers.length - 1]; // lowest tick
      let { top, bottom } = axis;
      return [tickMax, tickLow, top, bottom];
    }

    const tickLow = Number(axis.ticks[0]);
    const tickMax = Number(axis.ticks[axis.ticks.length - 1]);
    let { top, bottom } = axis;
    return [tickMax, tickLow, top, bottom];
  }
  get options() {
    return this.chart.options.lineHeightAnnotation
      ? this.chart.options.lineHeightAnnotation
      : false;
  }

  get tooltip() {
    return this.chart.tooltip;
  }

  get datasets() {
    return this.chart.config.data.datasets;
  }
}

const CTXS = {};
const CHARTS = {};
const STATES = {};

const plugin = {
  id: 'lineHeightAnnotation',
  afterDatasetDraw: (chart) => {
    const id = chart.canvas.getAttribute('id');
    const {
      ctx,
      chartArea: { left, bottom },
    } = chart;

    if (id === 'line-chart-1') {
      const canvasState = chart.ctx.getImageData(
        0,
        0,
        chart.canvas.width,
        chart.canvas.height
      );
      Object.assign(CHARTS, { [id]: chart });
      Object.assign(CTXS, { [id]: chart.ctx });
      Object.assign(STATES, { [id]: canvasState });
      const chart1meta = [];
      const lineChartUtils = new LineChartUtils(chart);
      const options = lineChartUtils.options;
      const yAxis = chart.scales[options.yAxis ? options.yAxis : 'y-axis-0'];
      const [, , , bottomY] = lineChartUtils.getMaximumDimensions(yAxis);
      const datasets = lineChartUtils.datasets;

      datasets.forEach((set, i) => {
        chart1meta.push(chart.getDatasetMeta(i).data);
      });
      Object.defineProperty(LineChartUtils, 'chart1meta', {
        value: chart1meta,
        writable: true,
        enumerable: true,
        configurable: true,
      });
      Object.defineProperty(LineChartUtils, 'chart1meta', {
        value: chart1meta,
        writable: true,
        enumerable: true,
        configurable: true,
      });
      Object.defineProperty(LineChartUtils, 'bottomY1', {
        value: bottomY,
        writable: true,
        enumerable: true,
        configurable: true,
      });
      Object.defineProperty(LineChartUtils, 'options', {
        value: options,
        writable: true,
        enumerable: true,
        configurable: true,
      });
    }
    if (id === 'line-chart-2') {
      const lineChartUtils = new LineChartUtils(chart);
      const options = lineChartUtils.options;
      const params = options?.params || { von: '', bis: '' };

      ctx.save();
      ctx.font = '12px Recursive';
      ctx.fillStyle = '#181818';
      ctx.fillText(
        `Quelle: FMH-Finanzberatung      Zeitraum ${params?.von} - ${params?.bis}`,
        left + 20,
        bottom - 15
      );
      ctx.restore();
      const optionsHandler = new AnnotationRenderer(ctx, options);

      const xAxis = chart.scales['x'];
      const yAxis = chart.scales['y'];
      // Activity pages don't need this functionality.
      if (!yAxis) {
        return;
      }

      if (!xAxis) {
        return;
      }
      const [, , , bottomY] = lineChartUtils.getMaximumDimensions(yAxis);
      const datasets = lineChartUtils.datasets;
      const meta = [];
      datasets.forEach((set, i) => {
        meta.push(chart.getDatasetMeta(i).data);
      });
      if (
        lineChartUtils.isTooltipActive() &&
        lineChartUtils?.tooltip?._active?.[0]?.element?.options?.hoverRadius !==
          5
      ) {
        const canvas1 = document.getElementById('line-chart-1');
        const activePoint = lineChartUtils.tooltip._active[0];
        const xSpace = activePoint.element.x;
        if (canvas1) {
          const ctx1 = canvas1.getContext('2d');
          CTXS['line-chart-1'].putImageData(STATES['line-chart-1'], 0, 0);
          Object.defineProperty(LineChartUtils, 'needUpdate', {
            value: false,
            writable: true,
            enumerable: true,
            configurable: true,
          });
          const optionsHandler2 = new AnnotationRenderer(
            ctx1,
            LineChartUtils.options
          );
          meta.map((item) => {
            const points = item.filter((point) => {
              return point.x === xSpace;
            });
            points.forEach((point) => {
              optionsHandler.drawLineHeightAnnotation(
                xSpace,
                -bottomY,
                point.y
              );
            });
          });
          LineChartUtils.chart1meta.map((item) => {
            const points = item.filter((point) => {
              return point.x === xSpace;
            });
            points.forEach((point) => {
              if (xSpace && point.y) {
                Object.defineProperty(LineChartUtils, 'needUpdate', {
                  value: true,
                  writable: true,
                  enumerable: true,
                  configurable: true,
                });
              }
              optionsHandler2.drawLineHeightAnnotation(
                xSpace,
                LineChartUtils.bottomY1 + 6,
                point.y + 2
              );
            });
          });
        } else {
          meta.map((item) => {
            const points = item.filter((point) => {
              return point.x === xSpace;
            });
            points.forEach((point) => {
              optionsHandler.drawLineHeightAnnotation(xSpace, bottomY, point.y);
            });
          });
        }
      } else {
        const canvas1 = document.getElementById('line-chart-1');
        if (canvas1 && LineChartUtils.needUpdate) {
          CTXS['line-chart-1'].putImageData(STATES['line-chart-1'], 0, 0);
          Object.defineProperty(LineChartUtils, 'needUpdate', {
            value: false,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
      }
    }
    if (id === 'line-chart-3') {
      const lineChartUtils = new LineChartUtils(chart);
      const options = lineChartUtils.options;
      const params = options?.params || { von: '', bis: '' };

      ctx.save();
      ctx.font = '12px Recursive';
      ctx.fillStyle = '#181818';
      ctx.fillText(
        `Quelle: FMH-Finanzberatung      Zeitraum ${params?.von} - ${params?.bis}`,
        left + 20,
        bottom - 15
      );
      ctx.restore();
    }
  },
};

export default plugin;
