<template>
  <LineChartGenerator
    :chart-options="options"
    :chart-data="chartData"
    :chart-id="chartId"
    :plugins="plugins"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
  Filler,
} from 'chart.js';

import {
  CHART_OPTIONS,
  COLOR,
  INTEREST_OPTIONS,
  SPECIAL_CHART_FORM_OPTIONS,
  SPECIAL_CHART_FORM_SUB_OPTIONS,
  SUB_INTEREST_OPTIONS,
} from '@/utils/constants';

import annotationPlugin from '@/plugins/annotationPlugin';
import { generateChartLabel } from '@/utils/helpers';

ChartJS.register(
  Filler,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement
);

export default {
  name: 'LineChart',
  components: { LineChartGenerator },
  props: {
    isDemoUser: {
      type: Boolean,
      default: true,
    },
    padding: Object,
    showXTicks: {
      type: Boolean,
      default: true,
    },
    chartId: String,
    data: Object,
  },
  data() {
    return {
      chartData: { labels: [], datasets: [] },
      color: COLOR,
    };
  },

  computed: {
    plugins() {
      return [annotationPlugin];
    },
    chartParams() {
      return this.data?.params || {};
    },
    chartDataRaw() {
      return this.data?.data || [];
    },

    isShowYAxisTitle() {
      return (
        this.chartParams?.selectedSpecialValue === 'rbGrafikAuswahl5' &&
        this.chartParams?.selectedSpecialSubValue?.length < 3
      );
    },
    options() {
      const config = {
        ...CHART_OPTIONS,
        layout: {
          padding: this.padding,
        },
        animations: false,
        scales: {
          x: {
            ...CHART_OPTIONS.scales.x,
            ticks: {
              ...CHART_OPTIONS.scales.x.ticks,
              beginAtZero: true,
            },
          },
          y: {
            ...CHART_OPTIONS.scales.y,
            title: {
              display: this.isShowYAxisTitle,
              text: this.isShowYAxisTitle ? this.generateYAxisTitle('y') : '',
            },
            ticks: {
              ...CHART_OPTIONS.scales.y.ticks,
              beginAtZero: false,
              callback: (val, index, values) => {
                const min = Math.min(...values.map((val) => val.value));
                const annotations = this.chartDataRaw
                  .filter((item) => typeof item.annotation === 'number')
                  .map((item) => item?.annotation);
                if (
                  annotations.length &&
                  annotations.some((item) => item !== min)
                ) {
                  this.$emit('updateDataAnnotations', min);
                }
                if (
                  this.chartParams?.selectedSpecialValue !==
                    'rbGrafikAuswahl5' &&
                  this.chartParams?.selectedSpecialValue !== 'goldpreis'
                ) {
                  return `${val.toLocaleString('de-DE', {
                    minimumFractionDigits: 2,
                  })}`;
                }
                return `${val.toLocaleString('de-DE', {
                  minimumFractionDigits:
                    this.chartParams?.selectedSpecialSubValue?.[0]?.includes(
                      'zins'
                    )
                      ? 2
                      : 0,
                })}`;
              },
            },
          },
        },
        onHover: (e, chartElement) => {
          const target = e.native ? e.native.target : e.target;
          if (
            this.isDemoUser &&
            this.$router.currentRoute.name !== 'rate-chart'
          ) {
            target.style.cursor = 'default';
          } else {
            target.style.cursor = chartElement[0] ? 'pointer' : 'default';
          }
        },
        plugins: {
          legend: {
            ...CHART_OPTIONS.plugins.legend,
            labels: {
              ...CHART_OPTIONS.plugins.legend.labels,
              generateLabels: (chart) => {
                return chart.data.datasets.map((dataset) => ({
                  text: `${dataset.label}                  `,
                  fillStyle: dataset.backgroundColor,
                  strokeStyle: dataset.borderColor,
                  fontColor: dataset.borderColor,
                }));
              },
            },
          },
          tooltip:
            this.isDemoUser && this.$router.currentRoute.name !== 'rate-chart'
              ? { enabled: false }
              : {
                  caretSize: 0,
                  mode: 'nearest',
                  position: 'custom',
                  yAlign: 'bottom',
                  xAlign: 'center',
                  titleMarginBottom: 3,
                  callbacks: {
                    title: (context) => {
                      if (context[0].dataset?.hint) {
                        const item = this.chartDataRaw.find(
                          (item, index) => index === context[0].dataIndex
                        );
                        return `${item.hint || ''}`;
                      } else {
                        let value = `${Number(context?.[0]?.raw || 0)
                          .toFixed(2)
                          .replace('.', ',')}`;
                        const label = context[0].label.replace(',', '.');
                        if (!context[0].dataset?.prefix) {
                          return `${label}: ${value} %`;
                        } else {
                          if (context[0].dataset?.prefix === 'percents') {
                            return `${label}: ${value} %`;
                          }
                          value = `${Number(
                            context?.[0]?.raw.toFixed(2)
                          ).toLocaleString('de-DE', {
                            minimumFractionDigits: 0,
                          })}`;
                          return `${label}: ${value} €`;
                        }
                      }
                    },
                    label: () => {
                      return null;
                    },
                  },
                },
        },
        lineHeightAnnotation: {
          params: () => {
            const von = this.data?.params?.von;
            const bis = this.data?.params?.bis;

            return this.data?.params
              ? {
                  von: this.$date(von).format('DD.MM.YYYY'),
                  bis: this.$date(bis).format('DD.MM.YYYY'),
                }
              : {};
          },
          id: this.chartId,
          // colors of the line
          color: '#FF0000',
          // name of yAxis
          yAxis: 'y-axis-0',
          // weight of the line
          lineWeight: 1,
        },
      };
      if (this.chartParams?.selectedSpecialValue === 'rbGrafikAuswahl5') {
        Object.assign(config.scales, {
          y1: {
            title: {
              display: this.isShowYAxisTitle,
              text: this.isShowYAxisTitle ? this.generateYAxisTitle('y1') : '',
            },
            type: 'linear',
            display: this.chartParams?.selectedSpecialSubValue?.length < 3,
            position: 'right',
            grid: {
              drawOnChartArea: false,
            },
            ticks: {
              beginAtZero: true,
              callback: (val) => {
                return `${Number(val.toFixed(2)).toLocaleString('de-DE')}`;
              },
            },
          },
        });
      }
      return config;
    },
  },
  watch: {
    chartParams() {
      this.loadChart();
    },
    chartDataRaw() {
      this.loadChart();
    },
  },
  methods: {
    generateYAxisTitle(axis) {
      const labels = {
        hp_hypothekenzins_index:
          'Bauzinsen 10 Jahre fest Quartalsmittelwert in %',
        hp_darlehenhoehe: 'Mögliche Kredithöhe in Euro',
        hp_einkommen: 'Nettoeinkommen p. M. für 2 Personen in Euro',
        hp_preis_neubau_haus: 'Durchschnittliche Neubau-Hauspreise in Euro',
        hp_preis_neubau_etw: 'Durchschnittliche Neubau-ETW-Preise in Euro',
      };
      if (axis === 'y') {
        return labels[this.chartParams.selectedSpecialSubValue[0]] || '';
      }
      return labels[this.chartParams.selectedSpecialSubValue[1]] || '';
    },
    getChartColor: function (i) {
      return this.$store.getters['chartColors/getColor'](i).color;
    },
    loadChart() {
      if (!!this.chartDataRaw.length) {
        const prefixes = {
          hp_hypothekenzins_index: 'percents',
          hp_darlehenhoehe: 'euro',
          hp_einkommen: 'euro',
          hp_preis_neubau_haus: 'euro',
          hp_preis_neubau_etw: 'euro',
        };
        const zins1 = this.chartDataRaw
          .filter((item) => item?.zins1)
          .map((item) => item?.zins1.toFixed(2));
        const zins2 = this.chartDataRaw
          .filter((item) => item?.zins2)
          .map((item) => item?.zins2);
        const zins3 = this.chartDataRaw
          .filter((item) => item?.zins3)
          .map((item) => item?.zins3);
        this.chartData.labels = [];
        this.chartData.datasets = [];

        for (let i = 0; i < this.chartDataRaw.length; i++) {
          const arr = [];
          const paths = this.$date(this.chartDataRaw[i].datum)
            .format('DD.MM.YYYY')
            .split('.');
          arr.push(`${paths[0]}.${paths[1]}`);
          arr.push(paths[2]);
          this.chartData.labels.push(arr);
        }
        if (!!zins1.length) {
          let yAxisID = '';
          if (this.chartParams.selectedSpecialValue === 'rbGrafikAuswahl5') {
            yAxisID = 'y';
          }
          let prefix = 'percents'; // default for all values
          if (this.chartParams.selectedSpecialValue === 'goldpreis') {
            prefix = 'euro';
          }
          if (prefixes[this.chartParams.selectedSpecialSubValue?.[0]]) {
            prefix = prefixes[this.chartParams.selectedSpecialSubValue?.[0]];
          }
          this.addDatasetZins(
            this.getChartColor(this.chartParams.colors?.zins1),
            'zins1',
            this.chartParams?.series?.zins1?.chartType
              ? this.chartParams.series.zins1.chartType
              : 'line',
            yAxisID,
            prefix
          );
        }
        if (!!zins2.length) {
          let yAxisID = '';
          if (this.chartParams?.selectedSpecialValue === 'rbGrafikAuswahl5') {
            yAxisID = 'y1';
          }
          const prefix =
            prefixes[this.chartParams.selectedSpecialSubValue?.[1]] ||
            'percents';
          this.addDatasetZins(
            this.getChartColor(this.chartParams.colors.zins2),
            'zins2',
            this.chartParams?.series?.zins2?.chartType
              ? this.chartParams.series.zins2.chartType
              : 'line',
            yAxisID,
            prefix
          );
        }
        if (!!zins3.length) {
          const prefix =
            prefixes[this.chartParams.selectedSpecialSubValue?.[2]] ||
            'percents';
          this.addDatasetZins(
            this.getChartColor(this.chartParams.colors.zins3),
            'zins3',
            this.chartParams?.series?.zins3?.chartType
              ? this.chartParams.series.zins3.chartType
              : 'line',
            '',
            prefix
          );
        }
        if (this.chartParams.annotation) {
          this.addDatasetZins('#FF0000', 'annotation');
        }
        const o = {};
        if (!this.chartParams.differentialValue) {
          if (this.chartParams.chartType === 'base') {
            const res = generateChartLabel(
              this.chartParams.series,
              this.chartDataRaw,
              INTEREST_OPTIONS,
              SUB_INTEREST_OPTIONS,
              'base',
              this.chartParams
            );
            Object.assign(o, res);
          } else {
            const res = generateChartLabel(
              { zins1: '', zins2: '', zins3: '' },
              this.chartDataRaw,
              SPECIAL_CHART_FORM_OPTIONS,
              SPECIAL_CHART_FORM_SUB_OPTIONS,
              'special',
              this.chartParams
            );
            Object.assign(o, res);
          }
          this.chartData.datasets = this.chartData.datasets.map((item) => {
            return {
              ...item,
              label: o[item.label] || '',
            };
          });
        } else {
          const formData = [];
          Object.keys(this.chartParams.series || {}).forEach((key) => {
            let str = '';
            let value = '';
            if (this.chartParams?.series?.[key]?.value) {
              const option = INTEREST_OPTIONS.find(
                (item) => item.value === this.chartParams.series[key].value
              );
              str = `${option.text}`;
              value = option.text;
            }
            if (this.chartParams?.series?.[key]?.subValue) {
              const subValue = SUB_INTEREST_OPTIONS[
                this.chartParams?.series?.[key]?.value
              ].find(
                (item) =>
                  item.value === this.chartParams?.series?.[key]?.subValue
              );
              str = `${value} - ${subValue?.text || ''}`;
            }
            formData.push({ [key]: str });
          });
          const arr = [];
          const strings = [];

          formData.forEach((item) => {
            const [[, val]] = Object.entries(item);
            if (val) {
              arr.push(val);
            }
          });
          arr.forEach((item, index) => {
            if (index === 0) {
              strings.push(`Differenz: ${item}`);
            }
            if (index === arr.length - 1) {
              strings.push(`${item} \n`);
            }
          });

          const zins = this.chartDataRaw
            .filter((item) => item?.zins1)
            .map((item) => item?.zins1);
          const label = [];
          const min = Math.min(...zins);
          const max = Math.max(...zins);
          const average =
            zins.reduce((all, a) => all + Number(a), 0) / zins.length;
          label.push(`Min: ${min.toFixed(2)}`);
          label.push(`Max: ${max.toFixed(2)}`);
          label.push(`Durchschnitt: ${average.toFixed(2)}`);
          Object.assign(o, {
            zins1: strings.join(' und ') + label.join('    '),
          });

          this.chartData.datasets = this.chartData.datasets.map((item) => {
            return {
              ...item,
              label: o[item.label] || '',
            };
          });
        }
      }
    },

    addDatasetZins(color, dataKey, chartType, yAxisID, prefix) {
      const dataset = {};
      dataset.label = dataKey;
      dataset.yAxis = 'y-axis-0';
      dataset.xAxis = 'x-axis-0';
      dataset.prefix = prefix;
      if (yAxisID) {
        dataset.yAxisID = yAxisID;
      }
      if (dataKey === 'annotation') {
        dataset.backgroundColor = 'transparent';
        dataset.pointBorderColor = 'transparent';
        dataset.borderColor = 'transparent';
        dataset.pointBackgroundColor = this.color;
        dataset.pointRadius = 5;
        dataset.pointHoverRadius = 4;
        dataset.pointHoverBackgroundColor = color;
        dataset.hitRadius = 5;
        dataset.order = 1;
        dataset.hint = 'true';
      }
      if (dataKey !== 'annotation') {
        switch (chartType) {
          case 'line':
            dataset.backgroundColor = 'transparent';
            dataset.pointBorderColor = 'transparent';
            dataset.borderColor = this.hexToRGB(color);
            dataset.pointHoverRadius = 5;
            dataset.pointRadius = 5;
            dataset.hitRadius = 5;
            dataset.pointHoverBackgroundColor = this.isDemoUser
              ? 'transparent'
              : '#fff';
            dataset.pointHoverBorderColor = this.isDemoUser
              ? 'transparent'
              : this.hexToRGB(color);
            dataset.lineTension = 0;
            dataset.fill = true;
            dataset.borderWidth = 1.5;
            dataset.order = 2;
            break;
          case 'area':
            dataset.borderWidth = 1;
            dataset.borderColor = this.hexToRGB(color);
            dataset.pointHoverRadius = 5;
            dataset.pointRadius = 5;
            dataset.pointBackgroundColor = 'transparent';
            dataset.pointHoverBackgroundColor = this.isDemoUser
              ? 'transparent'
              : '#fff';
            dataset.pointHoverBorderColor = this.isDemoUser
              ? 'transparent'
              : this.hexToRGB(color);
            dataset.pointBorderColor = 'transparent';
            dataset.fill = true;
            const canvas = document.getElementById('line-chart-2');
            const chartContext = canvas.getContext('2d');
            const gradientFill = chartContext.createLinearGradient(
              0,
              0,
              0,
              400
            );
            gradientFill.addColorStop(0.2, this.hexToRGBA(color, 0.9));
            gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0.5)');
            gradientFill.addColorStop(1, this.hexToRGBA(color, 0.9));
            dataset.backgroundColor = gradientFill;
            dataset.order = 3;
            break;
          default:
            dataset.backgroundColor = 'transparent';
            dataset.pointBorderColor = 'transparent';
            dataset.borderColor = this.hexToRGB(color);
            dataset.fill = true;
            dataset.pointRadius = 0;
            dataset.pointHitRadius = 0;
            dataset.order = 2;
        }
      }
      dataset.lineTension = 0;
      dataset.data = [];

      for (let i = 0; i < this.chartDataRaw.length; i++) {
        dataset.data.push(this.chartDataRaw[i][dataKey]);
      }
      this.chartData.datasets.push(dataset);
    },
  },
  mounted() {
    this.loadChart();
  },
};
</script>
